<template>
  <!-- 编辑子账号 -->
  <div class="page-container">
    <div class="page-top-tool">
      <el-page-header @back="goBack" content="编辑子账号"></el-page-header>
    </div>
    <div class="form-layout">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="账 号" prop="username">
          <el-input v-model="form.username" size="mini" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="密 码" prop="pwd">
          <el-input type="password" show-password v-model="form.pwd" size="mini" placeholder="请输入网点名称" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="员工姓名">
          <el-input v-model="form.ename" size="mini" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="form.phone" size="mini" style="width: 300px;"></el-input>
        </el-form-item>
      </el-form>
      <div class="bottom-submit-btn">
        <el-button type="primary" size="mini" @click="submitForm('ruleForm')">提 交</el-button>
        <!-- <el-button size="mini" @click="resetForm('ruleForm')">重 置</el-button> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateSonAccountPage',
  data() {
    return {
      form: {
        id: '',
        username: '',
        pwd: '',
        ename: '',
        phone: '',
        state: '',
        prower: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    };
  },

  mounted() {
    const updateSonAccount = sessionStorage.getItem('updateSonAccount')
    if (updateSonAccount) {
      this.form = JSON.parse(updateSonAccount)
    } else {
      this.$message.warning('信息失效，请重新选择')
      this.$router.push({
        path: '/sonAccount'
      })
    }
  },

  methods: {
    goBack() {
      this.$router.back()
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http({
            method: 'post',
            url: 'sysuser_update',
            params: this.form
          })
          .then(res => {
            if (res.errno == 0) {
              this.$message.success('修改成功')
              this.$router.push({
                path: '/sonAccount'
              })
            } else {
              this.$message.error('子账号修改失败')
            }
          })
          .catch(err => {
            this.$message.error('修改失败')
            console.error('子账号修改异常：', err.message)
          })
        } else {
          return false;
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 3px;
  box-sizing: border-box;
  padding: 20px 25px;
  overflow-y: scroll;

  .page-top-tool {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }

  .form-layout {
    margin: 30px 0px;
    padding: 10px 30px;
  }

  .bottom-submit-btn {
    padding: 10px 50px;
  }
}

/* 修改滚动条样式 */
.page-container::-webkit-scrollbar {
	width: 4px;
}
.page-container::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	background: rgba(0, 0, 0, 0.2);
}
</style>